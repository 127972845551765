<template>
  <div class="account-bind">
    <div class="account-card">
      <img
        class="weixin-log"
        src="../../assets/images/企业微信@2x.png"
        alt=""
      >
      <div class="top-title">企微信息</div>
      <div class="person-card">
        <img
          :src="user.avatar"
          alt=""
          :onerror="defaultImg"
        />
        <div class="person-detail">
          <div class="name">{{ user.name }}</div>
        </div>
      </div>
      <div class="divider"></div>
      <div class="info-user">
        <div class="user-title">
          当前绑定账号
        </div>
        <div
          class="account"
          style="margin-top: 16px"
        >
          <span>账号：</span>
          {{ user.account }}
        </div>
        <div
          class="name"
          style="margin-top: 8px"
        >
          <span>姓名：</span>
          {{ user.proName }}
        </div>
      </div>

      <div class="btn-group">
        <van-button
          @click="show=true"
          class="un-bing"
        >解绑</van-button>
        <van-button
          to='/concatAccount?changeBind=true'
          class="bind"
          type="info"
        >换绑</van-button>
      </div>
    </div>

    <van-action-sheet
      v-model="show"
      :actions="actions"
      cancel-text="取消"
      close-on-click-action
      @select="unBind"
    />
  </div>
</template>

<script>
import mine from '@/api/mine';

export default {
  name: 'accountBind',
  data() {
    return {
      show: false,
      actions: [
        { name: '是否进行账号解绑', color: '#909399', disabled: true, className: 'unbind-title' },
        { name: '解绑', color: '#FA5D5D', className: 'unbind-sure' },
      ],
      qwUserId: '',
      user: {}
    };
  },
  computed: {
    defaultImg() {
      return 'this.src="' + require('@/assets/images/default_head_icon.png') + '"';
    },
  },
  methods: {
    //获取人员信息
    async getUser() {
      let saleUserId = JSON.parse(localStorage.getItem('platform_role')).saleUserId;
      let { code, data } = await mine.getSaleInfo({ saleUserId });
      if (code === '0') {
        this.$set(this.user, 'proName', data.name);
        this.$set(this.user, 'account', data.account);
      }
    },
    //解绑
    async unBind(val) {
      if (val.name === '解绑') {
        let opId = localStorage.getItem('platform_opId');
        let { code } = await mine.unbindUser(this.qwUserId, { opId });
        if (code === '0') {
          await this.loginOut();
        }
      }
    },
    //退出登录态
    async loginOut() {
      let { code } = await mine.loginOut();
      if (code === '0') {
        this.$router.push('/nopermission');
      }
    }
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem('platform_user'));
    this.qwUserId = this.user.userId;
    this.getUser();
  }
};
</script>

<style lang="less" scoped>
.account-bind {
  position: relative;

  .account-card {
    border-radius: 4px;
    position: absolute;
    left: 16px;
    top: 24px;
    width: 319px;
    padding: 24px 12px;
    background-color: @gary-white;

    .weixin-log {
      position: absolute;
      top: 0px;
      right: 12px;
      width: 79px;
    }

    .top-title {
      font-size: 12px;
      font-weight: 400;
      color: @gary-wxb-gray-3;
      line-height: 17px;
    }

    .person-card {
      margin-top: 16px;
      display: flex;
      align-items: center;

      img {
        width: 48px;
        height: 48px;
        border-radius: 48px;
        margin-right: 13px;
      }

      .person-detail {
        .name {
          font-size: 14px;
          font-weight: bold;
          color: #333333;
          line-height: 20px;
        }

        .position {
          font-size: 12px;
          font-weight: 400;
          color: @gary-wxb-gray-3;
          line-height: 17px;
        }
      }
    }

    .divider {
      margin-top: 24px;
      height: 0.5px;
      background: #f6f6f6;
    }

    .info-user {
      margin-top: 24px;

      .user-title {
        font-size: 12px;
        font-weight: 400;
        color: @gary-wxb-gray-3;
        line-height: 17px;
      }

      .account,
      .name,
      .role {
        & > span {
          color: @gary-wxb-gray-3;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    .btn-group {
      display: flex;
      margin-top: 32px;
      .un-bing {
        width: 134px;
        background-color: #f0f4fe;
        color: @blue;
      }

      .bind {
        margin-left: 8px;
        width: 201px;
      }
    }
  }
}
</style>
<style lang="less">
.account-bind {
  .unbind-title span {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .unbind-sure span {
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
  }
}
</style>
